(function($, ns) {
  "use strict";

  var cfg = {
    cache: {
      container: '[data-component="syte.sidebar-team"]',
      results: ".results",
      home: ".score-card-home",
      guests: ".score-card-guests",
      datetime: ".score-card-game-information"
    },
    classes: {
      loading: "loading"
    },
    data: {
      teamId: "team-id"
    },
    events: {},
    options: {},
    scripts: {
      teamCall: `${window.location.protocol}//${
        document.location.hostname
      }/wp-json/volley_api/teams/{teamId}/last`
    },
    template: {
      scoreCard:
        '<div class="score-card">' +
        '<div class="score-card-game-information">{{Date}}</div>' +
        '<div class="score-card-home">{{Home}}<span class="score">{{HomeScore}}</span></div>' +
        "<hr>" +
        '<div class="score-card-guests">{{Guests}}<span class="score">{{GuestsScore}}</span></div>' +
        "</div>"
    }
  };

  ns.SidebarTeam = function(container) {
    this.container = container;
    this.settings = cfg;
    this.init();
  };

  ns.SidebarTeam.prototype = {
    init: function() {
      if (this.container.length) {
        this.cacheItems();
        this.bindEvents();
        this.activate();
      }
    },
    cacheItems: function() {
      var cache = this.settings.cache;

      this.win = $(window);

      this.container = $(cache.container);

      this.results = this.container.find(cache.results);
      this.home = this.container.find(cache.home);
      this.guests = this.container.find(cache.guests);
      this.datetime = this.container.find(cache.datetime);
    },
    bindEvents: function() {},
    activate: function() {
      this.getLastMatches();
    },

    getLastMatches: function() {
      var settings = this.settings,
        classes = settings.classes,
        data = settings.data,
        scripts = settings.scripts;

      var self = this;
      var teamId = this.container.data(data.teamId);
      var teamCall = scripts.teamCall.replace("{teamId}", teamId);

      this.container.addClass(classes.loading);
      $.ajax({
        type: "GET",
        url: teamCall,
        success: function(data) {
          if (data.matches) {
            self.setDataToSidebar(self, data);
          }
        }
      }).done(function() {
        self.container.removeClass(classes.loading);
      });
    },

    setDataToSidebar: function(self, data) {
      var scoreCard = self.settings.template.scoreCard;
      var matches = data.matches.reverse();
      if (matches.length <= 0) {
        self.results.append(
          "<div class='results-no'>Er zijn nog geen wedstrijden gespeeld.</div>"
        );
      } else {
        for (var i = 0; i < matches.length; i++) {
          var m = matches[i];
          var date = new Date(m.date);
          var dateFormatted =
            m.day + " " + date.toLocaleDateString("nl-BE") + " " + m.hour;
          var cardData = {
            Date: dateFormatted,
            Home: self.stripSeriesFromTeam(m.home.name),
            HomeScore: m.score.substring(0, 1),
            Guests: self.stripSeriesFromTeam(m.guests.name),
            GuestsScore: m.score.substring(3)
          };
          var card = ns.fn.renderTemplate(cardData, scoreCard);

          self.results.append(card);

          var home = $(self.results.find(self.settings.cache.home)[i]);
          var guests = $(self.results.find(self.settings.cache.guests)[i]);
          self.checkIfHomeTeam(home.text())
            ? home.addClass("home-team")
            : guests.addClass("home-team");
        }
      }
    },

    stripSeriesFromTeam: function(teamName) {
      return teamName.substring(0, teamName.indexOf("("));
    },

    checkIfHomeTeam: function(teamName) {
      return teamName.indexOf("LEEFDAAL") >= 0;
    }
  };

  return ns;
})(window.jQuery, window.Syte || {});
